.autocomplete-input{
  width: 50%;
  position: absolute;
  top: 2%;
  left: 10%;
  z-index: 2;
  margin: 32px auto;
  border: none !important;
  background-color: rgba(255,255,255,0.9);
  border-radius: 8px; 
}

@media only screen and (max-width: 800px)  {
  .autocomplete-input{
    width: 80vw;    
  }
}

.autocomplete-input.initial{
  position: absolute;
  margin-top: 0;
  top: 45%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); 
}

.autocomplete-input input{
  font-weight: bold;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root{
  padding: 0 !important;
}