.info-container{
  height: auto;
  min-height: 5rem;
  border-radius: 8px;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  background-color: #F5F5F5;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.info_area-name{
  width: 50%;  
  font-size: 1.2rem;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 4px 4px 4px 24px;
}

.info_percentages{
  width: 35%;
  border: 1px solid silver;
  border-radius: 8px;
  font-size: 1rem;
  margin: 8px 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #D5AFF8;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.info_percentages small{
  font-size: 12px;
}

.info_percentages h2, h3, h4{
  margin: auto;
}