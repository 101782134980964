.video{
  height: 4rem;
  width: 100%;
  /* border-bottom: 1px solid grey; */
  display: flex;
  flex-direction: row;
}

.video-profile{
  /* margin: 4px; */
  /* border: 1px solid black; */
  /* border-radius: 8px; */
  height: auto;
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;  
}

.video-profile p{
  font-size: 10px;
  text-align: center;
}

.video-profile img{
  max-width: 100%;
  max-height: 100%;
}

.video-info{
  display: flex;
  flex-direction: column;
  width: 60%;
  height: auto;
  padding: 0 16px;
}

/* .video-info small{
  font-size: 10px;
  position: absolute;
  bottom: 0;
  right: 12%;
} */

.video-info__link{
  display: flex;
  align-items: center;
  height: 90%;
  overflow: hidden;
}

.video-info__link p{
  font-size: 12px;
  font-weight: bold;
  margin: 2px 0;
}

.video-info__scheme{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 10px;
  font-weight: bold;
  height: 40%;
}

.video-info__scheme p{
  margin: 0;
}