.map-container-parent {
  height: calc(100vh);
  overflow: hidden;
  /* background-color: #619ad4; */
}

.map-container {
  height: calc(100vh - 112px);
  width: auto;
  overflow: hidden;
}

.drawer_tabs {
  height: 400px;
  overflow: scroll;
}

@media only screen and (min-width: 1000px) {
  .map-container-parent {
    height: 100vh;
    overflow: hidden;
  }

  .map-container {
    height: calc(100vh - 112px);
    width: auto;
  }

  .custom-attribution {
    bottom: 60px !important;
    right: 4px !important;
  }

  .initialUI {
    height: 100vh;
    overflow: hidden;
  }

  .drawer_tabs {
    height: 500px;
    overflow: scroll;
  }

  .video-responsive_parent {
    width: 80vh;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 800px) {
  .drawer_tabs {
    height: 500px;
    overflow: scroll;
  }
}


.latlong-watermark {
  z-index: 2;
  top: 0%;
  right: 0%;
  position: absolute;
  width: 128px;
  height: 32px;
  opacity: 0.7;
}

.latlong-watermark img {
  max-height: 100%;
  max-width: 100%;
}

.latlong-watermark h5 {
  margin-top: -10px;
  text-align: center;
  color: #4381c3;
}

.mapboxgl-ctrl-bottom-right, .maplibregl-ctrl-bottom-right {
  bottom: 2%;
  right: 0;
}

.blur-background {
  position: relative;
  /* filter: blur(1px); */
  z-index: 1;
  height: 100vh;
  width: 100vw;
  /* background-color: cornflowerblue; */
}

.blur-background img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bjp_logo {
  position: absolute;
  z-index: 2;
  top: 0%;
  left: 0%;
  height: 100px;
  width: 100px;
  padding: 4px;
  /* opacity: 0.7; */
}

.bjp_logo img {
  height: 50%;
  width: 50%;
}


.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 90%;
  min-height: 600px;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
}

.modal-close {
  position: fixed;
  right: 2rem;
  top: 2rem;
  background-color: #76BA99;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  z-index: 10;
  cursor: pointer;
  color: #fff;
  padding: 3px;
  border: 0;
}

.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 90%;
  height: 90%;
  overflow: hidden;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  animation-name: animatetop;
  animation-duration: 0.4s
}


@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0
  }

  to {
    top: 0;
    opacity: 1
  }
}

.viewer-wrapper {
  border: 1px solid #76BA99;
  height: 100%;
}

.top-bar {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 5px 1rem;
  border-bottom: 1px solid #000;
  width: 80%;
}

.rpv-core-text-layer {
  pointer-events: none;
}

.rpv-page-navigation__current-page-input {
  display: flex !important;
  width: 3rem !important;
}

/* Style for custom attribution */
.custom-attribution {
  position: absolute;
  bottom: 60px;
  right: 0px;
  padding-left: 2px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 4px;
  font-size: 12px;
  width: max-content;
  text-align: end;
  height: min-content;
}

.custom-attribution img {
  height: 20px;
  position: relative;
  top: 6px;
}

@media only screen and (max-width: 600px) {
  .custom-attribution {
    display: block !important;
    bottom: 60px;
  }

  .mapboxgl-ctrl-attrib.mapboxgl-compact-show {
    padding: 0 !important;
    visibility: visible;
  }
}

.mapboxgl-ctrl-bottom-left {
  bottom: 0;
  left: 0;
  display: none;
}

.mapboxgl-ctrl-attrib.mapboxgl-compact .mapboxgl-ctrl-attrib-inner {
  display: block !important;
}

.mapboxgl-ctrl-attrib.mapboxgl-compact {
  min-height: 0px !important;
  height: 0px !important;
  padding: 0px !important;
}

.mapboxgl-ctrl-attrib-button {
  visibility: hidden;
}
