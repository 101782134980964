body {
  font-family: 'Itim', sans-serif;
}

/* container */

.container_bg-color {
  /* background: radial-gradient(circle at 18.7% 37.8%, rgb(250, 250, 250) 0%, rgb(225, 234, 238) 90%); */
  /* background: radial-gradient(circle at 18.7% 37.8%, #F5FCFF 0%, #92DFF3 90%); */
  display: flex;
  justify-content: center;
}

/* header */

/* CSS */
.header {
  /* background: linear-gradient(to right, #d6d6d6, #b1b1b1); */
  background: radial-gradient(circle at 18.7% 37.8%, #F5FCFF 0%, #92DFF3 90%);

  /* background: linear-gradient(90deg, rgba(249,125,9,1) 43%, rgba(39,141,39,1) 100%); */
  /* Add other styles for the header, such as height, padding, etc. */
}

.rectangle-box {
  width: 50%;
  /* border: 2px solid black; */
  border-radius: 10px;
  margin: 60px 0;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  min-height: 150px;
  /* Initial height of 150px */
  height: fit-content;
  transition: height 0.3s;
  /* Add transition for smooth height change */
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

/* Add the .expanded class to dynamically increase the height */
.rectangle-box.expanded {
  height: fit-content;
}

.area_list-item {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 4px !important;
  cursor: pointer;
}

/* .area_list-item:hover {
  background-color: rgba(0, 0, 0, 0.04);
} */

.chart-container {
  width: 100%;
  height: 16em;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 5px;
  background-color: transparent;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.au_right-align {
  font-size: 10px;
  color: #b1b1b1;
  text-align: right;
}

.au_left-align {
  text-align: left;
}

.button {
  background-color: #4381c3;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  align-self: center;
}

.select-element {
  margin: 2px 0;
  /* 2px margin in the x-axis */
  padding: 0;
  /* Remove padding */
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 4px 30px 4px 4px !important;
}

.card-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  justify-items: center;
  /* Center align items horizontally */
  padding-top: 1rem;
}

.card {
  width: 200px;
  height: 200px;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  color: rgb(240, 240, 240);
  box-shadow: 0 10px 30px 5px rgba(0, 0, 0, 0.2);
  font-family: 'Roboto', sans-serif;
  border-radius: 8px !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px !important;
  transition: transform 0.3s, box-shadow 0.3s, filter 0.3s;
  /* Add transition for smooth scaling, box shadow change, and filter effect */
}

.card:hover {
  transform: scale(1.02);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(67, 129, 195, 1) 0px 8px 16px -8px !important;
}

.card img {
  position: absolute;
  object-fit: unset;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.9;
  transition: opacity .2s ease-out;
  filter: blur(0);
  /* Initially no blur on the image */
  transition: filter 0.3s;
  /* Add transition for smooth blur effect */
}

.card:hover img {
  filter: blur(1px);
  /* Apply blur effect on hover */
}

.card:hover .card-content {
  background-color: #D9E5F3;
  opacity: 1;
  /* Bring the card details text forward by making it fully opaque */
  transform: scale(1.1);
  /* Increase the scale of the card details text by 10% on hover */
}

.card h2 {
  position: absolute;
  inset: auto auto 30px 30px;
  margin: 0;
  transition: inset .3s .3s ease-out;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: normal;
  text-transform: uppercase;
}

.card-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 30px;
  opacity: 0;
  transition: opacity .3s ease-out;
  transform: scale(1);
  /* Initial scale of 1 for the card details text */
  transition: opacity 0.3s, transform 0.3s;
  /* Add transition for smooth opacity change and scaling */
}

.card-content p {
  margin: 0;
  font-size: 14px;
}

.card-content a {
  color: inherit;
  text-decoration: none;
  font-size: 14px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.material-symbols-outlined {
  vertical-align: middle;
}

.card:hover h2 {
  inset: auto auto 220px 30px;
  transition: inset .3s ease-out;
}

.card:hover .card-content {
  opacity: 1;
  transition: opacity .5s .1s ease-in;
}

.card:hover img {
  transition: opacity .3s ease-in;
  opacity: 1;
}

.card.selected {
  /* Add the styles for the clicked card */
  /* For example, you can use a border or background color to indicate the selected card */
  border: 2px solid #4381c3;
}

.card.selected:hover {
  /* You can add styles for the selected card on hover */
  /* For example, you can change the border color or increase the scale on hover */
}

.query_details {
  height: 480px;
  width: 100%;
  /* padding: 10px 20px; */
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  /* margin-top: 16px; */
}

@media (min-width: 767px) {
  .query_details {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    height: auto;
  }
}

.area_selection-div {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
}

.infographics_action_div {
  height: auto;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px dotted #d3d3d3;
  padding: 8px 16px;
}

.year_details {
  height: fit-content;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.share_section {
  display: flex;
  justify-content: end;
}

.summary-container {
  margin-top: 16px;
}

.customToggleButton {
  border: none !important;
  width: 100%;
  display: flex;
  justify-content: left !important;
}

.customAutocompleteList:not(:last-child) {
  border-bottom: 1px solid silver;
}


/* tryout */
.customAutocompleteList {
  border-bottom: 1px solid silver;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.customAutocompleteList.selected {
  background-color: rgba(25, 118, 210, 0.12);
}

.customAutocompleteList:last-child {
  border-bottom: none;
}

.toggleContainer {
  display: flex;
  align-items: center;
}

.toggleButton {
  width: 24px;
  height: 12px;
  border-radius: 18px;
  background-color: #ccc;
  margin-left: 10px;
  cursor: pointer;
}

.toggleButton.active {
  background-color: #4381c3;
}

.toggleButton:before {
  content: '';
  position: relative;
  display: block;
  width: 9.6px;
  height: 9.6px;
  border-radius: 50%;
  background-color: white;
  top: 1.2px;
  left: 1.2px;
  transition: transform 0.3s ease-in-out;
}

.toggleButton.active:before {
  transform: translateX(9.6px);
}


/* end */

/* Media queries for responsive design */

/* Mobile devices (up to 767px) */
@media (max-width: 767px) {
  .rectangle-box {
    width: 90%;
    /* Adjust the width for mobile screens */
    margin: 20px auto;
    /* Add margin for sides */
    min-height: 200px;
    height: fit-content;
  }

  .infographics_action_div {
    flex-direction: column-reverse;
    gap: 8px
  }
}

@media (max-width: 768px) {
  .select-element {
    line-height: 1.5;
    /* Increase line height for mobile view */
  }
}

@media (max-width: 767px) {
  .card {
    width: 100px;
    height: 100px;
  }
}

@media (max-width: 767px) {
  .query_details {
    height: auto;
    flex-direction: column;
    gap: 12px;
    /* padding-bottom: 16px; */
    align-items: center;
  }
}

.template_btn-div {
  display: flex;
  width: 100%;
  margin-top: 32px;
  justify-content: space-around;
}

.cusomization_section {
  display: flex;
}

/* react colorful */

.colorCustomizaion-items {
  display: flex;
  flex-direction: column;
  padding: 0 16px;
}

.colorCustomizaion-items small {
  text-align: center;
}

.picker {
  position: relative;
  /* margin-left: 16px; */
  align-self: center;
}

.swatch {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 3px solid #fff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.popover {
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  border-radius: 9px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* Custom layout */
.custom-layout .react-colorful {
  padding: 16px;
  border-radius: 12px;
  background: #d3d3d3;
  box-shadow: 0 6px 12px #999;
}

.custom-layout .react-colorful__saturation {
  margin: 15px 0;
  border-radius: 5px;
  border-bottom: none;
}

.custom-layout .react-colorful__hue {
  order: -1;
}

.custom-layout .react-colorful__hue,
.custom-layout .react-colorful__alpha {
  height: 14px;
  border-radius: 5px;
}

.custom-layout .react-colorful__hue-pointer,
.custom-layout .react-colorful__alpha-pointer {
  width: 20px;
  height: 20px;
}

.styled-div {
  position: relative;
  padding: 10px;
  border: 2px solid #1976d2;
  /* Blue border color, change to your desired color */
  color: #000;
  /* Text color, change to your desired color */
  border-radius: 4px;
}

.styled-div::before {
  content: '';
  position: absolute;
  top: -10px;
  /* Adjust the top position to place it above the border */
  left: 14%;
  /* Center the text horizontally */
  background-color: #fff;
  /* Background color, change to your desired color */
  padding: 0 4px;
  transform: translateX(-50%);
  /* Center the text horizontally */
  font-size: 14px;
  white-space: nowrap;
  /* Prevent the text from wrapping */
  /* border: 2px solid #2196F3;  */
  border-radius: 4px;
  font-size: 14px;
  color: #1976d2;
}

.styled-div_area {
  position: relative;
  padding: 10px;
  border: 2px solid #1976d2;
  /* Blue border color, change to your desired color */
  color: #000;
  /* Text color, change to your desired color */
  border-radius: 4px;
}

.styled-div_area::before {
  content: 'Constituency';
  position: absolute;
  top: -10px;
  /* Adjust the top position to place it above the border */
  left: 14%;
  /* Center the text horizontally */
  background-color: #fff;
  /* Background color, change to your desired color */
  padding: 0 4px;
  transform: translateX(-50%);
  /* Center the text horizontally */
  font-size: 14px;
  white-space: nowrap;
  /* Prevent the text from wrapping */
  /* border: 2px solid #2196F3;  */
  border-radius: 4px;
  font-size: 14px;
  color: #1976d2;
}



.pill-chip {
  background-color: #007bff;
  /* Choose your desired background color */
  color: white;
  /* Text color for the pill */
  border-radius: 50px;
  /* Adjust the border-radius to control the pill shape */
  padding: 5px 12px;
  /* Adjust the padding to control the pill size */
  font-size: 16px !important;
  font-weight: 500;
}

/* Custom select styles for the h2 element */
.mui-select {
  position: relative;
  display: inline-block;
  border-bottom: 2px solid #2196f3;
  /* Blue underline color */
  padding-right: 20px;
  /* Add space for the down arrow icon */
  cursor: pointer;
  user-select: none;
  /* Prevent text selection */
  color: black;
  /* Text color */
  font-family: 'Itim', sans-serif;
  /* Font family */
}

/* Custom select arrow icon */
.mui-select-icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 16px;
  /* Adjust the font size as needed */
  color: #2196f3;
  /* Arrow color */
}

.styled-div_area {
  position: relative;
  min-width: 120px;
  text-align: center;
  padding: 2px;
  border: 2px solid #1976d2;
  /* Blue border color, change to your desired color */
  color: #000;
  /* Text color, change to your desired color */
  background-color: #ffffff;
  border-radius: 4px;
  padding-left: 6px;
  padding-right: 6px;
  margin-top: 12px !important;
  margin-bottom: 6px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.styled-div_area::before {
  content: 'Constituency';
  position: absolute;
  top: -10px;
  /* Adjust the top position to place it above the border */
  left: 50px;
  /* Center the text horizontally */
  background-color: #fff;
  /* Background color, change to your desired color */
  padding: 0 4px;
  transform: translateX(-50%);
  /* Center the text horizontally */
  font-size: 14px;
  white-space: nowrap;
  /* Prevent the text from wrapping */
  /* border: 2px solid #2196F3;  */
  border-radius: 4px;
  font-size: 14px;
  color: #1976d2;
}

.styled-div_scheme {
  position: relative;
  padding: 2px;
  border: 2px solid #1976d2;
  /* Blue border color, change to your desired color */
  color: #000;
  /* Text color, change to your desired color */
  background-color: #ffffff;
  border-radius: 4px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 6px;
  padding-right: 6px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.styled-div_scheme::before {
  content: 'Scheme';
  position: absolute;
  top: -10px;
  /* Adjust the top position to place it above the border */
  left: 32px;
  /* Center the text horizontally */
  background-color: #fff;
  /* Background color, change to your desired color */
  padding: 0 4px;
  transform: translateX(-50%);
  /* Center the text horizontally */
  font-size: 14px;
  white-space: nowrap;
  /* Prevent the text from wrapping */
  /* border: 2px solid #2196F3;  */
  border-radius: 4px;
  font-size: 14px;
  color: #1976d2;
}

.styled-div_state {
  position: relative;
  padding: 2px;
  border: 2px solid #1976d2;
  /* Blue border color, change to your desired color */
  color: #000;
  /* Text color, change to your desired color */
  background-color: #ffffff;
  border-radius: 4px;
  margin-top: 12px;
  margin-bottom: 12px;
  padding-left: 6px;
  padding-right: 6px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.styled-div_state::before {
  content: 'State';
  position: absolute;
  top: -10px;
  /* Adjust the top position to place it above the border */
  left: 25px;
  /* Center the text horizontally */
  background-color: #fff;
  /* Background color, change to your desired color */
  padding: 0 4px;
  transform: translateX(-50%);
  /* Center the text horizontally */
  font-size: 14px;
  white-space: nowrap;
  /* Prevent the text from wrapping */
  /* border: 2px solid #2196F3;  */
  border-radius: 4px;
  font-size: 14px;
  color: #1976d2;
}

.styled-div_marginZero {
  margin: 0 !important;
}

.styled-div_marginEight {
  padding-top: 8px;
}

.styled-div_underline {
  border-bottom: 2px solid #1976d2;
  display: flex;
  justify-content: space-between;
}

.custom-select {
  border: none !important;
  padding: 0 !important;
  appearance: none;
  /* Remove default appearance */
  -webkit-appearance: none;
  /* Remove default appearance for Webkit browsers */
  -moz-appearance: none;
  /* Remove default appearance for Mozilla browsers */
}

/* pdf viewer modal */

.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 90%;
  min-height: 600px;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
}

.modal-close {
  position: fixed;
  right: 2rem;
  top: 2rem;
  background-color: #76BA99;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  z-index: 10;
  cursor: pointer;
  color: #fff;
  padding: 3px;
  border: 0;
}

.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 90%;
  height: 90%;
  overflow: hidden;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  animation-name: animatetop;
  animation-duration: 0.4s
}


@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0
  }

  to {
    top: 0;
    opacity: 1
  }
}

.viewer-wrapper {
  border: 1px solid #76BA99;
  height: 100%;
}

.top-bar {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 5px 1rem;
  border-bottom: 1px solid #000;
  width: 80%;
}

.rpv-core-text-layer {
  pointer-events: none;
}

.rpv-page-navigation__current-page-input {
  display: flex !important;
  width: 3rem !important;
}

.rpv-search__popover-input-counter {
  width: 9rem !important;
}

.rpv-search__popover-label {
  display: none !important;
}

.rpv-search__popover {
  display: flex;
}

.carousel .slide img {
  height: 400px !important;
}

.carousel .carousel-status {
  color: gray !important;
  font-size: 18px !important;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.4);
}

.carousel.carousel-slider .control-arrow {
  color: #000 !important;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #000 !important;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #000 !important;
}

.carousel .control-dots {
  width: 100% !important;
  /* left: 25%; */
  background: rgba(0,0,0,0.4);
  border: 1px solid rgba(0,0,0,0.4);
  border-radius: 16px;
  padding: 4px !important
}

.carousel .control-dots .dot {
  width: 10px !important;
  height: 10px !important;
}

.swiper {
  width: 100%;
  height: 420px;
}

.hide_pagination {
  display: none !important;
}

.show_pagination {
  display: block !important;
}