.manifesto-wrapper{
  /* border: 1px dashed blue; */
}

.manifesto-itemA{
  height: auto;
  min-height: 5rem;
  border-radius: 8px;
  margin-bottom: 12px;
  display: flex;
  align-items: start;
  justify-content: flex-start;
  flex-direction: column;
  background-color: rgba(137, 207, 240, 0.6);
  padding: 16px;
  box-shadow: rgb(99 99 99 / 60%) 0px 2px 8px 0px;
}

.manifesto-itemP{
  height: auto;
  min-height: 5rem;
  border-radius: 8px;
  margin-bottom: 12px;
  display: flex;
  align-items: start;
  justify-content: flex-start;
  flex-direction: column;
  background-color: rgba(137, 207, 240, 0.2);
  padding: 16px;
  box-shadow: rgb(99 99 99 / 60%) 0px 2px 8px 0px;
}

.manifesto-subheading{
  display: flex; 
  align-items: center; 
}

.manifesto-headline{
  font-size: 18px;
  text-align: left;
}

.manifesto-publishedDate{
  display: flex;
  justify-content: flex-end;
  width: 100%;
  font-size: 12px;
}

.manifesto-details{
  margin: 0;
  /* text-align: justify; */
  /* text-justify: auto; */
}

.manifesto-capitalize{
  text-transform: capitalize !important;
  font-size: 14px !important;
}