.beneficiary-container{
  height: 5rem;
  width: auto;
  border-radius: 8px;
  margin: 8px 0;
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  background-color: #F5F5F5;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

}

.beneficiary_area-name{
  padding: 4px;
  height: inherit;
  width: 60%;
  font-size: 1.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

.beneficiary_area-name h5{
  padding-top: 16px;
  margin: 2px 0;
}

.beneficiary_area-name small{
  margin: 2px 0;
  font-size: 12px;
}


.beneficiary_count{
  height: auto;
  width: 40%;
  border: 1px solid silver;
  border-radius: 8px;
  margin: 8px 4px;

  font-size: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #D5AFF8;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.beneficiary_count h3{
  margin: 2px 0;
}

.beneficiary_count small{
  margin: 2px 0;
  font-size: 10px;
  font-weight: bold;
}