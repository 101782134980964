/* Pagination css */
.item {
  align-items: center;
  color: rgba(0,0,0,0.87);
  cursor: pointer;
  display: flex;
  font-size: 14px;
  height: 40px;
  justify-content: center;
  width: 40px;
  border: solid 1px rgba(0,0,0,0.23);
  border-radius: 40px;
  color: #808e9b;
  margin: 4px;
}

.item link{
  width: inherit;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item a{
  width: inherit;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}

.disabled-page {
  /* color: #808e9b; */
  opacity: 0.38;
}

.active {
  border-radius: 40px;
  color: #1976d2;
  border: 1px solid rgba(25, 118, 210, 0.5);
  background-color: rgba(25, 118, 210, 0.12);
}

.break-me {
}

.next {
  border-left: solid 1px #808e9b;
  font-size: 4px;
  height: 40px;
  position: absolute;
  right: 0;
  width: 40px !important;
}

.pagination {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  height: 60px;
  justify-content: center;
  list-style-type: none;
  position: relative;
  width: 100%;
  padding: 0;
}

.pagination-page {
  font-weight: 700;
}

.previous {
  border-right: solid 1px #808e9b;
  font-size: 4px;
  height: 40px;
  left: 0;
  position: absolute;
  width: 40px;
}

.navigation_container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
}

/* end */